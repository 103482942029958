<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-item-group
          :style="`min-height: 60vh; width: 100%; position: relative`"
          class="d-flex flex-column"
        >
          <v-alert
            v-if="
              employeeData.length > 0 &&
              getUserProfile.level.find(
                ({ id }) =>
                  id == appLevel.administrator || id == appLevel.adminMaster
              ) !== undefined
            "
            class="bounce2"
            color="warning"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-alert"
            dense
            style="
              position: fixed;
              top: 75px;
              left: 10px;
              z-index: 1;
              cursor: pointer;
            "
            @click="openWarningDialog()"
          >
            Ada <strong>{{ employeeData.length }}</strong> karyawan resign yang
            <strong>BPJS</strong> nya masih aktif
          </v-alert>
          <div>
            <v-container
              style="
                background: rgba(255, 255, 255, 0.6);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
              "
            >
              <v-row no-gutters style="font-size: 18px; padding: 0; margin: 0">
                <v-col cols="12">
                  <div id="clock" style="margin-bottom: 20px">
                    <p class="date">{{ days }} {{ date }}</p>
                    <p class="time">{{ time }}</p>
                  </div>
                  <div class="d-flex flex-column justify-center align-center">
                    <p style="margin: 0; padding: 0; font-size: 14px">
                      {{ publicIp }}
                    </p>
                    <a
                      style="font-size: 12px"
                      href="#"
                      @click.prevent="networkDialog"
                      >Lihat wifi terdaftar!</a
                    >
                  </div>
                  <v-dialog v-model="openNetworkDialog">
                    <v-card style="position: relative">
                      <v-card-text class="d-flex justify-center align-center">
                        <v-col
                          md="12"
                          style="padding: 0; position: relative; top: 20px"
                        >
                          <v-data-table
                            mobile-breakpoint="0"
                            fixed-header
                            style="cursor: pointer"
                            :headers="headers"
                            :items="result"
                          >
                            <template v-slot:[`item.plant`]="{ item }">
                              <div
                                style="
                                  font-size: 12px;
                                  padding: 0;
                                  display: flex;
                                  flex-direction: row;
                                "
                              >
                                {{ item.plant.name }}
                              </div>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-card-text>
                      <v-card-actions
                        style="
                          position: absolute;
                          top: 0;
                          right: 0;
                          z-index: 99;
                        "
                      >
                        <v-btn
                          icon
                          color="black"
                          text
                          @click="closeListNetwork"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <div class="note">
                    <div
                      style="position: absolute; top: 0; left: 0; padding: 10px"
                      class="d-flex justify-center align-start"
                    >
                      <v-icon
                        class="d-flex justify-center align-center"
                        style="margin: 3px 15px 0 10px"
                        color="primary lighten-2"
                        size="22px"
                        >mdi-information-outline</v-icon
                      >

                      <p style="margin: 0; padding: 0" class="subtitle-2">
                        Berdasarkan peraturan perusahaan, karyawan diharapkan
                        hadir pada jam <strong>08:00 </strong> dan pulang pada
                        jam
                        <strong> 17:00 </strong>
                      </p>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" style="margin-top: 20px">
                  <v-card
                    elevation="0"
                    class="mx-auto"
                    style="padding: 10px 15px"
                  >
                    <v-toolbar-title
                      class="overline"
                      style="
                        padding-left: 10px;
                        overflow: none;
                        text-align: center;
                      "
                    >
                      <p class="presensi-title">Presensi anda saat ini</p>
                    </v-toolbar-title>
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0">
                        <div
                          v-if="attendanceStatus !== null"
                          style="padding: 0 10px"
                        >
                          <div v-if="todayAttend !== null">
                            <v-row no-gutters style="text-align: center">
                              <v-col cols="12" sm="6">
                                <p
                                  style="
                                    margin: 0;
                                    padding-top: 20px;
                                    font-size: 14px;
                                    font-weight: 500;
                                  "
                                >
                                  Jam Masuk
                                </p>
                                <div
                                  v-if="
                                    permission.create_perm == 1 &&
                                    permission.update_perm == 1
                                  "
                                >
                                  <v-btn
                                    v-if="attendanceStatus === 'IN'"
                                    type="button"
                                    text
                                    outlined
                                    elevation="0"
                                    color="primary"
                                    class="white--text font-weight-bold ma-5"
                                    style="font-size: 12px"
                                    @click="
                                      openAttendanceForm('Presensi Masuk')
                                    "
                                  >
                                    Belum terekam
                                  </v-btn>
                                  <v-btn
                                    v-if="
                                      attendanceStatus === 'OUT' ||
                                      attendanceStatus === 'NOT_ALLOWED'
                                    "
                                    type="button"
                                    text
                                    outlined
                                    elevation="0"
                                    color="primary"
                                    class="white--text font-weight-bold ma-5"
                                    style="font-size: 12px"
                                    disabled
                                  >
                                    Sudah terekam
                                  </v-btn>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <p
                                  style="
                                    margin: 0;
                                    padding-top: 20px;
                                    font-size: 14px;
                                    font-weight: 500;
                                  "
                                >
                                  Jam Pulang
                                </p>
                                <div>
                                  <v-btn
                                    v-if="attendanceStatus === 'OUT'"
                                    type="button"
                                    text
                                    outlined
                                    elevation="0"
                                    color="primary"
                                    class="white--text font-weight-bold ma-5"
                                    style="font-size: 12px"
                                    @click="
                                      openAttendanceForm('Presensi Pulang')
                                    "
                                  >
                                    Belum terekam
                                  </v-btn>

                                  <v-btn
                                    v-if="attendanceStatus === 'NOT_ALLOWED'"
                                    type="button"
                                    text
                                    outlined
                                    elevation="0"
                                    color="primary"
                                    class="white--text font-weight-bold ma-5"
                                    style="font-size: 12px"
                                    disabled
                                  >
                                    Sudah terekam
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-dialog v-model="cameraDialog" fullscreen persistent>
                <v-card
                  style="position: relative; width: 100%; height: 100%"
                  class="d-flex justify-center align-center"
                >
                  <vue-camera
                    v-if="cameraDialog"
                    style="position: relative; height: 90%; z-index: 2"
                    @picture-taken="takePicture"
                  />
                  <v-card-actions
                    style="position: absolute; top: 0; right: 0; z-index: 99"
                  >
                    <v-btn icon color="black" text @click="closeCamera()">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
            <v-dialog v-model="dialog" width="500">
              <v-form
                :disabled="loading"
                ref="entryForm"
                @submit.prevent="submit"
                style="position: relative"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    {{ attendanceTitle }}
                  </v-card-title>

                  <v-card-text class="d-flex justify-center align-center">
                    <v-col
                      md="12"
                      style="padding: 0; position: relative; top: 20px"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Area Presensi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-autocomplete
                            v-model="form.attend_from"
                            :items="dropdown.area"
                            item-text="name"
                            item-value="id"
                            return-id
                            outlined
                            dense
                            clearable
                            style="margin: 0; height: 55px"
                            :rules="rules.attendFromRules"
                          ></v-autocomplete>
                        </v-col>
                      </div>
                      <div
                        v-if="form.attend_from === 1"
                        style="padding: 0 10px"
                      >
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Lokasi Kantor
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-autocomplete
                            :items="getDropdownPlant"
                            item-text="name"
                            item-value="id"
                            return-object
                            outlined
                            dense
                            clearable
                            v-model="form.attend_from_company"
                            style="margin: 0; height: 55px"
                            :rules="rules.attendFromCompanyRules"
                          ></v-autocomplete>
                        </v-col>
                      </div>
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            class="text-left"
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Deskripsi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-textarea
                            v-model="form.attend_description"
                            dense
                            auto-grow
                            outlined
                            style="margin: 0"
                            :rules="rules.descriptionRules"
                          />
                        </v-col>
                      </div>
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Upload Foto
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <div
                            style="
                              padding: 10px;
                              border: 2px dashed grey;
                              height: 70px;
                            "
                          >
                            <v-file-input
                              ref="photoProfile"
                              placeholder="-buka kamera-"
                              dense
                              append-icon="mdi-image"
                              prepend-icon=""
                              @change="uploadFoto"
                              :rules="rules.photoRules"
                              capture="camera"
                              accept="image/*"
                            ></v-file-input>
                          </div>
                        </v-col>
                      </div>
                    </v-col>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="close"
                      :loading="loading"
                    >
                      Batal
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      type="submit"
                      text
                      outlined
                      :loading="loading"
                    >
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <v-dialog
              v-if="detailAttendance !== null"
              v-model="detailPresensi"
              width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Detail Presensi
                </v-card-title>

                <v-card-text class="justify-center align-center">
                  <v-col
                    md="12"
                    style="padding: 0; position: relative; top: 20px"
                  >
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Area Presensi
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          :value="detailAttendance.attend_from"
                          dense
                          readonly
                          style="margin: 0"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col
                    v-if="detailAttendance.attend_from === 'Site'"
                    md="12"
                    style="padding: 0; position: relative; top: 20px"
                  >
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Lokasi
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          :value="detailAttendance.attend_from_company_name"
                          dense
                          readonly
                          style="margin: 0"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col
                    md="12"
                    style="padding: 0; position: relative; top: 20px"
                  >
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Deskripsi
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-textarea
                          :value="detailAttendance.attend_description"
                          dense
                          auto-grow
                          rows="1"
                          readonly
                          style="margin: 0"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col
                    md="12"
                    style="padding: 0; position: relative; top: 20px"
                  >
                    <v-row no-gutters style="padding: 0 10px">
                      <v-col cols="3" style="padding: 0">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Foto :
                        </p>
                      </v-col>
                      <v-col cols="9" style="padding: 0">
                        <v-dialog v-model="openImageDialog" max-width="80%">
                          <template v-slot:activator="{ on, attrs }">
                            <a href="#" v-bind="attrs" v-on="on" @click.prevent
                              >Lihat Gambar</a
                            >
                          </template>
                          <div style="position: relative">
                            <v-img
                              v-if="openImageDialog"
                              :src="imgSrc"
                              @error="imgError"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                </v-row> </template
                            ></v-img>
                            <v-btn
                              color="black"
                              fab
                              x-small
                              @click="closeImg"
                              style="position: absolute; top: 10px; right: 10px"
                            >
                              <v-icon color="white" style="font-weight: bold"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    md="12"
                    style="
                      padding: 0;
                      position: relative;
                      top: 20px;
                      margin-top: 10px;
                    "
                  >
                    <v-row no-gutters style="padding: 0 10px">
                      <v-col cols="3" style="padding: 0">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Lokasi :
                        </p>
                      </v-col>
                      <v-col cols="9" style="padding: 0">
                        <a
                          :href="`https://www.google.com/maps/search/?api=1&query=${detailAttendance.attend_from_loc.lt},${detailAttendance.attend_from_loc.lng}`"
                          target="_blank"
                          >Buka di google map</a
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    md="12"
                    style="
                      padding: 0;
                      position: relative;
                      top: 20px;
                      margin-top: 10px;
                    "
                  >
                    <v-row no-gutters style="padding: 0 10px">
                      <v-col cols="3" style="padding: 0">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Perangkat :
                        </p>
                      </v-col>
                      <v-col cols="9" style="padding: 0">
                        <p
                          style="padding: 0; margin: 0; font-size: 14px"
                          class="subtitle-1"
                        >
                          {{ detailAttendance.device_type }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    outlined
                    @click="detailPresensi = !detailPresensi"
                  >
                    Tutup
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-item-group>
        <div
          v-if="
            getUserProfile.level.find(
              ({ id }) =>
                id == appLevel.administrator ||
                id == appLevel.adminMaster ||
                id == appLevel.adminSupport
            ) !== undefined
          "
          style="
            min-height: 85vh;
            width: 100%;
            position: relative;
            margin-top: 25px;
          "
        >
          <!-- <div> -->
          <div class="containerAtas">
            <div class="dataKaryawan">
              <h1 class="judul">DATA KARYAWAN</h1>
              <div id="totalEmployees"></div>
              <canvas id="employeeChart" height="250"></canvas>
            </div>

            <div class="trained">
              <h1 class="judul">TRAINED</h1>
              <canvas id="trainedChart" height="250"></canvas>
            </div>
          </div>
          <!-- Kontainer Manpower, Gaji, Pendidikan -->⁡⁡⁡
          <div class="containerTengah">
            <div class="manpower">
              <h1 class="judul">MANPOWER</h1>
              <canvas id="manpowerChart"></canvas>
            </div>
            <div class="gaji">
              <h1 class="judul">GAJI</h1>
              <canvas id="gajiChart"></canvas>
            </div>
            <div class="pendidikan">
              <h1 class="judul">PENDIDIKAN</h1>
              <canvas id="pendidikanChart"></canvas>
            </div>
          </div>
          ⁡⁣⁢⁣
          <!-- Kontainer Grafik Jabatan & Status Karyawan -->⁡⁡
          <div class="containerKaryawan">
            <div class="karyawanPerjabatan">
              <h1 class="judul">GRAFIK JABATAN</h1>
              <canvas id="karyawanPerjabatanChart" height="250"></canvas>
            </div>

            <div class="karyawanStatus">
              <h1 class="judul">STATUS KARYAWAN</h1>
              <canvas id="karyawanStatusChart" height="250"></canvas>
            </div>
          </div>
          ⁡⁣⁢⁣
          <!-- Kontainer Absensi & Presensi -->⁡⁡
          <div class="containerAbsensi">
            <div class="dataAbsensi">
              <h1 class="judul">DATA ABSENSI</h1>
              <canvas id="absensiChart" height="250"></canvas>
            </div>

            <div class="performaAbsensi">
              <h1 class="judul">PRESENSI vs ABSENSI</h1>
              <canvas id="performaAbsensiChart" height="250"></canvas>
            </div>
          </div>
          ⁡⁣⁢⁣
          <!-- Kontainer Performa Karyawan & Turn Over -->⁡⁡
          <div class="containerPerforma">
            <div class="performaKaryawan">
              <h1 class="judul">PERFORMA KARYAWAN</h1>
              <canvas id="performaPresensiChart" height="300"></canvas>
            </div>
            <div class="turnOver">
              <h1 class="judul">TURN OVER</h1>
              <canvas id="turnOverChart" height="250"></canvas>
            </div>
          </div>

          <v-dialog v-model="warningDialog" persistent max-width="900px">
            <v-card>
              <v-card-title class="text-h5 black--text warning lighten-2">
                <v-icon color="black" style="margin-right: 5px"
                  >mdi-alert</v-icon
                >
                Segera nonaktifkan status BPJS karyawan dibawah ini
              </v-card-title>

              <v-card-text class="d-flex justify-center align-center">
                <v-col
                  md="12"
                  style="padding: 0; position: relative; top: 20px"
                >
                  <div style="padding: 0 10px">
                    <v-data-table
                      :items="employeeData"
                      :headers="employeeHeaders"
                      @click:row="rowClick"
                      mobile-breakpoint="0"
                      fixed-header
                      min-height="20vh"
                      hide-default-footer
                    >
                      <template v-slot:[`item.bpjs_status`]="{ item }">
                        <div
                          :style="`color:${
                            item.bpjs_status == 1 ? 'green' : 'red'
                          }; padding: 0;`"
                        >
                          {{ item.bpjs_status == 1 ? 'Aktif' : 'Tidak Aktif' }}
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="black"
                  text
                  outlined
                  @click="warningDialog = false"
                >
                  Tutup
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js"></script>
<!-- <script src="https://cdn.jsdelivr.net/npm/chart.js@3.0.0/dist/chart.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels@2.0.0"></script> -->
<script>
import $ from 'jquery'
import Chart from 'chart.js'
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import VueCamera from '../../home/components/Camera.vue'
const env = process.env.VUE_APP_BUILD_TYPE
// const NodeRSA = require('node-rsa')
export default {
  name: 'hrdashboard',
  components: {
    VueCamera
  },
  data: () => ({
    hrsApi: buildType.apiURL('hrs'),
    config: require('../../../services/config.json')[env],
    screenWidth: 0,
    screenHeight: 0,
    cameraDialog: false,
    dialog: false,
    detailPresensi: false,
    openImageDialog: false,
    openNetworkDialog: false,
    loading: false,
    attendance: buildType.apiURL('attendanceTrial'),
    upload: buildType.apiURL('upload2'),
    eSanQua: buildType.apiURL('esanqua'),
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    attendanceTitle: '',
    date: '',
    time: '',
    days: '',
    week: ['MINGGU', 'SENIN', 'SELASA', 'RABU', 'KAMIS', 'JUMAT', 'SABTU'],
    publicIp: '...',
    // wifiName: '...',
    todayAttend: null,
    detailAttendance: null,
    form: {
      device_code: null,
      device_type: 3,
      attend_from: null,
      attend_from_company: null,
      attend_loc_lt: null,
      attend_loc_lng: null,
      attend_description: null,
      attend_from_ip: null,
      attend_from_ssid: null,
      hashed_data: null,
      photo: null,
      date: null
    },
    isLocationSupported: false,
    rules: {
      attendFromRules: [],
      attendFromCompanyRules: [],
      descriptionRules: [],
      photoRules: []
    },
    dropdown: {
      area: [
        { id: 1, name: 'kantor' },
        { id: 2, name: 'Lokasi Lain' },
        { id: 3, name: 'Rumah' }
      ]
    },
    imgSrc: '',

    headers: [
      {
        text: 'Perusahaan',
        value: 'plant',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama Wifi',
        value: 'wifi_ssid',
        align: 'left',
        sortable: false
      },
      {
        text: 'IP',
        value: 'ip',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    attendanceStatus: null,
    warningDialog: false,
    employeeHeaders: [
      {
        text: 'NIK',
        value: 'nik',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'employee_status.name',
        align: 'left',
        sortable: false
      },
      {
        text: 'BPJS',
        value: 'bpjs_status',
        align: 'left',
        sortable: false
      }
    ],
    employeeData: []
  }),
  async mounted() {
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator

    this.screenWidth = screen.width
    this.screenHeight = screen.height

    // this.initChart()
    this.getDataMenu()
  },
  created() {
    // setInterval(this.updateTime, 1000)
    // this.updateTime()
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId', 'dropdownPlant']),
    ...mapMutations(['setTabs']),
    async getDataMenu() {
      this.isLoadingPage = true
      await this.getMenuId({
        keyword: '',
        path: this.$route.path
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },

    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm == 1) {
            this.permission = val.data
            setTimeout(() => {
              this.initChart()
              this.setArrTabs()

              this.getPublicIp()
              this.getLocation()
              if (val.data.read_perm == 1) {
                this.getTodayAttendance()
                this.getAttendanceStatus()
              }
              this.getNetworkPlant()
              if (
                this.getUserProfile.level.find(
                  ({ id }) =>
                    id == this.appLevel.administrator ||
                    id == this.appLevel.adminMaster
                ) !== undefined
              ) {
                this.getEmployeeData()
              }
            }, 10)
          }
        }
      }
      this.isLoadingPage = false
    },
    openWarningDialog() {
      console.log('dasda')
      setTimeout(() => {
        this.warningDialog = true
      }, 300)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    imgError() {
      // this.isImgError = true
      this.imgSrc =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    setArrTabs() {
      let tabsArr = [
        { tab_name: 'Dashboard', path: '/hr/dashboard', isShown: false },
        {
          tab_name: 'Pengajuan',
          path: '',
          sub_tabs: [
            {
              sub_title: 'Pengajuan Cuti',
              path: '/hr/leave'
            },
            {
              sub_title: 'Pengajuan Izin',
              path: '/hr/permission'
            },
            {
              sub_title: 'Aktivitas',
              path: '/hr/activity'
            },
            {
              sub_title: 'Lembur',
              path: '/hr/overtime'
            }
          ],
          isShown: false
        },
        {
          tab_name: 'Presensi',
          path: '',
          sub_tabs: [
            {
              sub_title: 'Report Presensi',
              path: '/hrs/presence_report'
            }
          ],
          isShown: false
        }
      ]

      if (this.userLevel.is_admin == 1) {
        // if (
        //   this.getUserProfile.level.find(
        //     ({ id }) => id == this.appLevel.adminTax
        //   ) === undefined
        // ) {

        // }

        // if (
        //   this.getUserProfile.level.find(
        //     ({ id }) => id == this.appLevel.adminMaster || id == this.appLevel.administrator
        //   ) !== undefined
        // ) {
        tabsArr[
          tabsArr.findIndex((x) => x.tab_name === 'Presensi')
        ].sub_tabs.push({
          sub_title: 'Log Kehadiran',
          path: '/hrs/attendance_log'
          // isShown: false
        })

        tabsArr.push(
          {
            tab_name: 'Master',
            path: '',
            isShown: false,
            sub_tabs: [
              {
                sub_title: 'Hirarki',
                path: '',
                sub_tabs: [
                  {
                    sub_title: 'Approval',
                    path: '/hr/ms/approval_hierarchy'
                  },
                  {
                    sub_title: 'Jabatan',
                    path: '/hr/ms/position_hierarchy'
                  },
                  {
                    sub_title: 'Wewenang',
                    path: '/hr/ms/authority'
                  }
                ]
              },
              {
                sub_title: 'Settingan Umum',
                path: '',
                sub_tabs: [
                  {
                    sub_title: 'Jabatan',
                    path: '/hr/ms/position'
                  },
                  {
                    sub_title: 'Department',
                    path: '/hr/ms/department'
                  },
                  {
                    sub_title: 'Hari Libur Nasional',
                    path: '/hr/ms/holidaysetting'
                  },
                  {
                    sub_title: 'Master Customer',
                    path: '/hr/ms/activity_customer'
                  },
                  {
                    sub_title: 'Kategori Cuti',
                    path: '/hr/ms/leave_category'
                  },
                  {
                    sub_title: 'Kategori Izin',
                    path: '/hr/ms/permission_category'
                  },
                  {
                    sub_title: 'Lowongan Kerja',
                    path: '/hr/ms/jobvacancy'
                  }
                ]
              },
              {
                sub_title: 'Jadwal',
                path: '',
                sub_tabs: [
                  // {
                  //   sub_title: 'Group',
                  //   path: '/hr/ms/group'
                  // },
                  {
                    sub_title: 'Shift',
                    path: '/hr/ms/shift'
                  },
                  {
                    sub_title: 'Jadwal Shift',
                    path: '/hr/ms/shift_schedule'
                  },
                  {
                    sub_title: 'Kategori',
                    path: '/hr/ms/shift_category'
                  }
                ]
              },
              {
                sub_title: 'Overtime',
                path: '',
                sub_tabs: [
                  {
                    sub_title: 'Overtime',
                    path: '/hr/ms/overtime'
                  },
                  {
                    sub_title: 'Overtime Rounding',
                    path: '/hr/ms/overtime/rounding'
                  },
                  {
                    sub_title: 'Overtime Multiplier',
                    path: '/hr/ms/overtime/multiplier'
                  }
                ]
              },
              {
                sub_title: 'BPJS',
                path: '',
                sub_tabs: [
                  {
                    sub_title: 'Ketentuan',
                    path: '/hr/ms/bpjsprovision'
                  },
                  {
                    sub_title: 'template',
                    path: '/hr/ms/bpjstemplate'
                  }
                ]
              },
              {
                sub_title: 'Payroll',
                path: '',
                sub_tabs: [
                  // {
                  //   sub_title: 'Template',
                  //   path: '/hr/ms/payroll/template'
                  // },
                  {
                    sub_title: 'Komponen Payroll',
                    path: '/hr/ms/payrollcomponent'
                  },
                  {
                    sub_title: 'Konfigurasi Umum',
                    path: '/hr/ms/config'
                  }
                ]
              }
            ]
          },
          {
            tab_name: 'Karyawan',
            path: '/hr/employee',
            isShown: false
          }
        )
        // }

        tabsArr[
          tabsArr.findIndex((x) => x.tab_name === 'Pengajuan')
        ].sub_tabs.push(
          {
            sub_title: 'Pinjaman',
            path: '/hr/loan'
          },
          {
            sub_title: 'COP',
            path: '/hr/cop'
          }
        )
      }

      if (this.userLevel.is_admin == 1 || this.userLevel.is_support) {
        if (
          this.getUserProfile.level.find(
            ({ id }) => id == this.appLevel.administrator
          ) === undefined
        ) {
          tabsArr.push(
            {
              tab_name: 'Payroll',
              path: '',
              sub_tabs: [
                {
                  sub_title: 'Komponen Tambahan',
                  path: '/hr/payroll/additional'
                },
                {
                  sub_title: 'Payroll',
                  path: '/hr/payroll'
                }
              ],
              isShown: false
            }
            // { tab_name: 'Recruitment', path: '/hr/recruitment', isShown: false }
          )
        }
      }

      this.setTabs(tabsArr)
    },
    getLocation() {
      if (navigator.geolocation) {
        this.isLocationSupported = true
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        this.isLocationSupported = false
      }
    },
    showPosition(position) {
      this.form.attend_loc_lt = position.coords.latitude
      this.form.attend_loc_lng = position.coords.longitude
    },
    uploadFoto(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.attendance')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.photo = res.data.data.name
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      } else {
        this.form.photo = null
      }
    },
    close() {
      this.rules = {
        attendFromRules: [],
        attendFromCompanyRules: [],
        descriptionRules: [],
        photoRules: []
      }
      this.dialog = false
      this.form = {
        device_code: null,
        device_type: 3,
        attend_from: null,
        attend_from_company: null,
        attend_loc_lt: null,
        attend_loc_lng: null,
        attend_description: null,
        attend_from_ip: null,
        attend_from_ssid: null,
        hashed_data: null,
        photo: null,
        date: null
      }
      this.getLocation()
      this.getPublicIp()
    },
    async openAttendanceForm(str) {
      this.attendanceTitle = str
      await this.dropdownPlant()
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    updateTime() {
      var cd = new Date()
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ':' +
        this.zeroPadding(cd.getMinutes(), 2) +
        ':' +
        this.zeroPadding(cd.getSeconds(), 2)
      this.days = `${this.week[cd.getDay()]}`
      this.date = `${this.zeroPadding(cd.getDate(), 2)}-${this.zeroPadding(
        cd.getMonth() + 1,
        2
      )}-${this.zeroPadding(cd.getFullYear(), 4)}`
    },
    zeroPadding(num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    },
    async getPublicIp() {
      // if (location.protocol === 'https:') {
      const data = await $.getJSON(
        'https://api.ipify.org?format=json',
        function (data) {
          return data
        }
      )

      this.publicIp = data.ip
      this.form.attend_from_ip = data.ip
    },

    submit() {
      this.rules.attendFromRules = [(v) => !!v || 'Attend from is required']

      if (this.form.attend_from === 1) {
        this.rules.attendFromCompanyRules = [
          (v) => !!v || 'Company is required'
        ]
      }

      if (this.form.attend_from > 1) {
        this.rules.descriptionRules = [(v) => !!v || 'Description is required']
      }
      if (this.form.photo === null) {
        this.rules.photoRules = [(v) => !!v || 'Photo is required']
      }
      const self = this

      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
      // navigator.permissions
      //   .query({ name: 'geolocation' })
      //   .then(PermissionStatus => {
      //     console.log(PermissionStatus.state)
      //     if (PermissionStatus.state == 'granted') {
      //       // put here
      //     } else {
      //       this.showMsgDialog('warning', 'Turn on your location access', false)
      //       //denied
      //     }
      //   })
    },
    createNewForm() {
      const form = {
        device_code:
          this.form.device_code !== null ? this.form.device_code : '',
        device_type: this.form.device_type,
        attend_from: this.form.attend_from,
        attend_from_company_id:
          this.form.attend_from_company !== null
            ? this.form.attend_from_company.id
            : null,
        attend_from_company_name:
          this.form.attend_from_company !== null
            ? this.form.attend_from_company.name
            : null,
        attend_loc_lt: this.form.attend_loc_lt,
        attend_loc_lng: this.form.attend_loc_lng,
        attend_description: this.form.attend_description,
        attend_from_ssid:
          this.form.attend_from_ssid !== null ? this.form.attend_from_ssid : '',
        attend_from_ip: this.form.attend_from_ip,
        hashed_data:
          this.form.hashed_data !== null ? this.form.hashed_data : '',
        picture: this.form.photo
      }

      if (this.attendanceStatus === 'IN' || this.attendanceStatus === 'OUT') {
        let val = null
        if (this.attendanceStatus === 'IN') {
          val = 1
        }
        if (this.attendanceStatus === 'OUT') {
          val = 2
        }

        Object.assign(form, { inOutMOde: val })
      }

      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.attendance}mobile_attendance/log/add`, form)
        .then(async (res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getAttendanceStatus()
            this.getTodayAttendance()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    getTodayAttendance() {
      const today = this.date.split('-').reverse().join('-')
      console.log('todayAttend start>>>>')
      axios
        .get(
          `${this.attendance}get_by_employee?date_from=${today}&date_to=${today}`
        )
        .then((res) => {
          console.log('todayAttend>>>>', res)
          if (res.data.status_code === '00') {
            this.todayAttend = res.data.data
          } else {
            this.todayAttend = []
          }
        })
        .catch((err) => {
          this.todayAttend = null
          console.log(err)
        })
    },
    getAttendanceStatus() {
      console.log('attendance status start>>>>')
      axios
        .get(`${this.attendance}get_last_attendance_status`)
        .then((res) => {
          console.log('attendance status>>>>', res)
          if (res.data.status_code === '00') {
            this.attendanceStatus = res.data.attendance_type
          } else {
            this.attendanceStatus = null
          }
        })
        .catch((err) => {
          this.attendanceStatus = null
          console.log(err)
        })
    },
    getNetworkPlant() {
      this.result = []
      axios
        .get(
          `${this.eSanQua}plantnetwork/list?keyword=&offset=0&limit=1000&plant_id=`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            this.result = res.data.data
          } else {
            this.result = []
          }
        })
        .catch((err) => {
          this.result = []
          console.log(err)
        })
    },
    openDetailAttendance(data) {
      this.detailAttendance = data
      if (process.env.VUE_APP_BUILD_TYPE === 'development') {
        this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${data.picture}`
      } else {
        this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${data.picture}`
      }
      setTimeout(() => {
        this.detailPresensi = true
      }, 300)
    },
    closeImg() {
      this.openImageDialog = false
      // this.isImgError = false
    },
    networkDialog() {
      setTimeout(() => {
        this.openNetworkDialog = true
      }, 300)
    },
    closeListNetwork() {
      setTimeout(() => {
        this.openNetworkDialog = false
      }, 300)
    },
    openCamera() {
      this.cameraDialog = true
      setTimeout(() => {
        document.getElementById('starCamera').click()
      }, 100)
    },
    closeCamera() {
      document.getElementById('stopCamera').click()
      setTimeout(() => {
        this.cameraDialog = false
      }, 100)
    },
    takePicture(event) {
      this.cameraDialog = false
      this.uploadFoto(event)
    },
    convertDate2(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = Intl.DateTimeFormat('id', {
          day: 'numeric',
          month: 'long'
        }).format(date)
        return local
      }
    },
    rowClick(pItem) {
      if (this.permission.read_perm == 1) {
        this.$router.push(`/hr/employee/detail/${pItem.id}`)
      }
    },
    async getEmployeeData() {
      let url = `${this.hrsApi}employee/list?company_id=${this.getUserProfile.employee.company.plant_id}&employee_status_id=5&bpjs_status=1&order_by=nik&order_type=asc&offset=0&limit=1000`
      await axios
        .get(url)
        .then((res) => {
          console.log('employee>>', res)
          if (res.data.status_code === '00') {
            this.employeeData = res.data.data
            if (this.employeeData.length > 0) {
              this.openWarningDialog()
            }
            return null
          }
          return (this.employeeData = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.employeeData = [])
        })
    },
    initChart() {
      if (
        this.getUserProfile.level.find(
          ({ id }) =>
            id == this.appLevel.administrator ||
            id == this.appLevel.adminMaster ||
            id == this.appLevel.adminSupport
        ) !== undefined
      ) {
        this.renderGajiChart()
        this.renderPendidikanChart()
        this.renderManpowerChart()
        this.renderEmployeeChart()
        this.renderTrainedChart()
        this.renderkaryawanPerjabatanChart()
        this.renderkaryawanStatusChart()
        this.renderperformaAbsensiChart()
        this.renderabsensiChart()
        this.renderperformaPresensiChart()
        this.renderturnOver()
      }
    },

    renderTrainedChart() {
      const ctxBar = document.getElementById('trainedChart').getContext('2d')
      new Chart(ctxBar, {
        type: 'bar',
        data: {
          labels: ['Modul', 'Participant'],
          datasets: [
            {
              label: 'Actual',
              data: [15, 10, 0],
              backgroundColor: 'rgba(54, 162, 235)',
              order: 1
            },
            {
              label: 'Plan',
              data: [19, 15, 0],
              backgroundColor: 'rgba(255, 99, 132, 0.8)',
              // barPercentage: 0.8,
              order: 2
            }
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                beginAtZero: true,
                position: 'left',
                ticks: {
                  min: 0,
                  max: 30,
                  stepSize: 5
                }
              }
            ]
          }
        }
      })
    },
    renderGajiChart() {
      const ctxBar = document.getElementById('gajiChart').getContext('2d')
      new Chart(ctxBar, {
        type: 'bar',
        data: {
          labels: ['Data 1', 'Data 2', 'Data 3'],
          datasets: [
            {
              label: 'Nilai',
              data: [10, 20, 30],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)'
              ],
              borderWidth: 2
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      })
    },
    renderPendidikanChart() {
      const ctxPie = document.getElementById('pendidikanChart').getContext('2d')
      new Chart(ctxPie, {
        type: 'pie',
        data: {
          labels: ['Data 1', 'Data 2', 'Data 3'],
          datasets: [
            {
              label: 'Nilai',
              data: [10, 20, 90],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)'
              ],
              borderWidth: 2
            }
          ]
        },
        options: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 20,
              fontSize: 12
            }
          }
        }
      })
    },
    renderManpowerChart() {
      const ctxBar = document.getElementById('manpowerChart').getContext('2d')
      new Chart(ctxBar, {
        type: 'bar',
        data: {
          labels: ['F&A', 'HCGS', 'PRO/MIS/IC', 'Dev&Sup', 'AAA', 'BBB', 'CCC'],
          datasets: [
            {
              label: 'Nilai',
              data: [30, 20, 10, 15, 20, 20, 20, 0],
              backgroundColor: [
                'rgba(255, 99, 132, 0.7)',
                'rgba(54, 162, 235, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(75, 192, 192, 0.7)',
                'rgba(153, 102, 255, 0.7)',
                'rgba(255, 159, 64, 0.7)',
                'rgba(255, 99, 132, 0.7)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(75, 192, 192, 0.5)'
              ],
              borderWidth: 2,
              tension: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          legend: {
            display: false,
            labels: {
              fontSize: 14
            }
          }
        }
      })
    },
    renderEmployeeChart() {
      const ctx = document.getElementById('employeeChart').getContext('2d')

      const maleEmployees = [30, 40, 20, 0]
      const femaleEmployees = [20, 35, 25, 0]

      const totalMaleEmployees = maleEmployees.reduce((a, b) => a + b, 0)
      const totalFemaleEmployees = femaleEmployees.reduce((a, b) => a + b, 0)
      const totalEmployees = totalMaleEmployees + totalFemaleEmployees

      // Menampilkan total keseluruhan karyawan di dalam #totalEmployees
      const totalEmployeesElement = document.getElementById('totalEmployees')
      totalEmployeesElement.innerHTML = `<p>Total: ${totalEmployees} orang</p>`

      // Membuat grafik
      new Chart(ctx, {
        type: 'horizontalBar',
        data: {
          labels: ['>35', '26-35', '18-25'],
          datasets: [
            {
              label: 'Laki-laki',
              data: maleEmployees,
              backgroundColor: 'rgba(54, 162, 235, 0.5)'
            },
            {
              label: 'Perempuan',
              data: femaleEmployees,
              backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                stacked: false
              }
            ],
            yAxes: [
              {
                stacked: false
              }
            ]
          }
        }
      })
    },
    renderkaryawanPerjabatanChart() {
      const ctx = document
        .getElementById('karyawanPerjabatanChart')
        .getContext('2d')
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: [
            'Head Corp',
            'Assist Head Corp',
            'Section Head',
            'SPV',
            'Officer',
            'Staff',
            'Admin/GE'
          ],
          datasets: [
            {
              label: 'Kuantitas Karyawan',
              data: [1, 1, 1, 4, 1, 4, 5, 0],
              backgroundColor: 'rgba(54, 162, 235, 0.5)'
            }
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                stacked: false
              }
            ],
            yAxes: [
              {
                stacked: false
              }
            ]
          }
        }
      })
    },
    renderkaryawanStatusChart() {
      const ctx = document
        .getElementById('karyawanStatusChart')
        .getContext('2d')
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Kontrak', 'Tetap', 'Magang', 'Pensiun'],
          datasets: [
            {
              label: 'Karyawan',
              data: [10, 5, 3, 2],
              backgroundColor: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(75, 192, 192, 0.5)'
              ]
            }
          ]
        },
        options: {
          legend: {
            position: 'left',
            labels: {
              boxWidth: 20,
              fontSize: 12
            }
          }
        }
      })
    },
    renderperformaAbsensiChart() {
      const maleEmployees = [300, 40, 20, 0]
      const femaleEmployees = [250, 35, 25, 0]

      const ctx = document
        .getElementById('performaAbsensiChart')
        .getContext('2d')
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Presensi', 'Absensi', 'Keterlambatan'],
          datasets: [
            {
              label: '2023',
              data: maleEmployees,
              backgroundColor: 'rgba(54, 162, 235, 0.5)'
            },
            {
              label: '2024',
              data: femaleEmployees,
              backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
          ]
        }
      })
    },
    renderabsensiChart() {
      const ctx = document.getElementById('absensiChart').getContext('2d')

      const absensiData = {
        'PT Sanqua': [10, 5, 3, 2, 4, 3, 0],
        'PT AAA': [8, 4, 2, 3, 5, 3]
      }

      const namaPT = ['PT Sanqua', 'PT AAA']

      const colors = ['RGBA(102, 204, 255, 1)', 'rgba(255, 159, 64, 1)']

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Terlambat', 'Dinas Luar', 'Cuti', 'Sakit', 'Izin', 'Alpa'],
          datasets: namaPT.map((namaPT, index) => ({
            label: `(${namaPT})`,
            data: absensiData[namaPT],
            backgroundColor: colors[index]
          }))
        },
        options: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 20,
              fontSize: 14,
              fontColor: 'black',
              padding: 20
            }
          }
        }
      })
    },
    renderperformaPresensiChart() {
      const ctx = document
        .getElementById('performaPresensiChart')
        .getContext('2d')
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
          datasets: [
            {
              label: 'Kehadiran',
              data: [60, 40, 30, 40, 100, 60, 70, 80, 90, 100, 80, 80],
              backgroundColor: 'rgba(30, 144, 255, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Persentasi Kehadiran (%)'
                },
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    return value + '%'
                  }
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Nama Karyawan'
                }
              }
            ]
          },
          legend: {
            display: false,
            labels: {
              fontSize: 14
            }
          }
        }
      })
    },
    renderturnOver() {
      var ctx = document.getElementById('turnOverChart').getContext('2d')

      // Data untuk chart
      var data = {
        labels: ['Karyawan Masuk', 'Karyawan keluar'],
        datasets: [
          {
            data: [70, 30],
            backgroundColor: [
              'rgba(75, 192, 192, 0.6)',
              'rgba(255, 99, 132, 0.6)'
            ],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 2
          }
        ]
      }

      // Konfigurasi chart
      var options = {
        cutoutPercentage: 50,
        rotation: Math.PI,

        legend: {
          display: true
        },
        tooltips: {
          enabled: true
        },
        animation: {
          animateRotate: false,
          animateScale: true
        }
      }

      // Membuat chart baru
      new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: options
      })
    }
  }
}
</script>
<style scoped lang="scss">
#clock {
  font-family: 'Share Tech Mono', monospace;
  // color: #ffffff;
  text-align: center;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  color: #525252;
  font-weight: bold;
  text-shadow: 0 0 20px rgba(9, 175, 230, 9), 0 0 20px rgba(10, 175, 230, 0);
  .time {
    letter-spacing: 0.05em;
    font-size: 80px;
    padding: 5px 0;
    margin: 0;
  }
  .date {
    letter-spacing: 0.1em;
    font-size: 24px;
    margin: 0;
  }
}

.note {
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 400px;
  min-height: 100px;
  margin: auto;
  padding: 10px;
  position: relative;
}
.presensi-title {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: indigo;
  margin-bottom: 10px;
}
.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-7px);
  }
}
@media (max-width: 576px) {
  #clock {
    .time {
      font-size: 3em;
    }
    .date {
      font-size: 1em;
    }
  }
}
@media (max-width: 320px) {
  .note {
    min-height: 130px;
  }
  .presensi-title {
    font-size: 14px;
  }
}

h1 {
  font-size: 36px;
  font-weight: bold;
  color: #4d4d4d;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

h1:hover {
  transform: scale(1.1);
  color: #ff6f61;
}
.judul {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.containerAtas,
.containerTengah,
.containerKaryawan,
.containerAbsensi,
.containerPerforma {
  display: flex;
  justify-content: center;
  margin: 0px 20px;
  max-height: 400px;
}

.containerKaryawan,
.containerPerforma {
  padding: 20px;
  background: #e2f3ff;
  border-radius: 40px;
}

.karyawanPerjabatan,
.karyawanStatus,
.performaKaryawan,
.turnOver {
  flex: 1;
}
.karyawanPerjabatan,
.performaKaryawan {
  flex: 30%;
}

.dataKaryawan,
.trained,
.manpower,
.gaji,
.pendidikan,
.dataAbsensi,
.performaAbsensi {
  flex: 1;
  justify-content: center;
  padding: 20px;
  border-radius: 40px;
  background: #e2f3ff;
  margin: 0 10px;
}

canvas {
  width: 100%;
  height: auto;
}
#totalEmployees {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .containerAtas,
  .containerTengah,
  .containerKaryawan,
  .containerAbsensi,
  .containerPerforma {
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    max-height: none;
  }

  .containerKaryawan,
  .containerPerforma {
    padding: 10px;
    border-radius: 40px;
    margin: 0 20px;
  }

  .karyawanPerjabatan,
  .karyawanStatus,
  .performaKaryawan,
  .turnOver {
    flex: 1;
  }

  .karyawanPerjabatan,
  .performaKaryawan {
    padding-top: 10px;
    flex: none;
    width: 100%;
  }

  .dataKaryawan,
  .trained,
  .manpower,
  .gaji,
  .pendidikan,
  .dataAbsensi,
  .performaAbsensi {
    flex: 1;
    margin: 10px 0;
  }
}
</style>
